import ko from 'knockout';
import { frameOrigin } from '@/platform/composables/useLightspeedBridge';

export const isInLightspeed = ko.observable(false);
export const lightspeedState = ko.observable({
  isQuickAddOpen: false,
  shouldUseLightspeedTaskDetails: false,
  parentHref: '',
});
export const lightspeedProjectChannelId = ko.observable(null);

export function postMessageToLightspeed(name, opts) {
  window.parent.postMessage({ name, ...opts }, frameOrigin);
}

export function redirectTo(url) {
  postMessageToLightspeed('twa:redirect', { url });
}

export function report401() {
  postMessageToLightspeed('twa:report-401');
}
